exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-bullion-index-js": () => import("./../../../src/pages/bullion/index.js" /* webpackChunkName: "component---src-pages-bullion-index-js" */),
  "component---src-pages-bullion-js": () => import("./../../../src/pages/bullion/[...].js" /* webpackChunkName: "component---src-pages-bullion-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-account-forgot-password-js": () => import("./../../../src/pages/my-account/forgot-password.js" /* webpackChunkName: "component---src-pages-my-account-forgot-password-js" */),
  "component---src-pages-my-account-index-js": () => import("./../../../src/pages/my-account/index.js" /* webpackChunkName: "component---src-pages-my-account-index-js" */),
  "component---src-pages-my-account-lost-password-js": () => import("./../../../src/pages/my-account/lost-password.js" /* webpackChunkName: "component---src-pages-my-account-lost-password-js" */),
  "component---src-pages-order-received-index-js": () => import("./../../../src/pages/order-received/index.js" /* webpackChunkName: "component---src-pages-order-received-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-refund-returns-index-js": () => import("./../../../src/pages/refund_returns/index.js" /* webpackChunkName: "component---src-pages-refund-returns-index-js" */),
  "component---src-pages-shop-clearance-index-js": () => import("./../../../src/pages/shop/clearance/index.js" /* webpackChunkName: "component---src-pages-shop-clearance-index-js" */),
  "component---src-pages-shop-clearance-js": () => import("./../../../src/pages/shop/clearance/[...].js" /* webpackChunkName: "component---src-pages-shop-clearance-js" */),
  "component---src-pages-shop-details-js": () => import("./../../../src/pages/shop/details/[...].js" /* webpackChunkName: "component---src-pages-shop-details-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop/[...].js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-terms-conditions-index-js": () => import("./../../../src/pages/terms_conditions/index.js" /* webpackChunkName: "component---src-pages-terms-conditions-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

