import React, { useMemo } from "react"
import { formatPrice, formatPriceForNonAuth } from "./utills"
const roundedAmount = (number = 0) =>
  (Math.round(number * 100) / 100).toFixed(2)
const Price = ({
  regularPrice,
  salePrice,
  currency,
  isRequiredCurrencySymball = false,
  needToFixed = false,
  count,
  quantityDiscounts,
}) => {
  const updatedCurrency = { ...currency }
  updatedCurrency.rate = "1"

  const getDiscountedPrice = useMemo(() => {
    let discPrice = 0
    quantityDiscounts &&
      quantityDiscounts.length > 0 &&
      quantityDiscounts.map(item => {
        if (
          item?.from &&
          item?.to &&
          count >= item?.from &&
          count <= item?.to
        ) {
          discPrice = item.discountedPrice
        } else if (item?.from && !item?.to && count >= item?.from) {
          discPrice = item.discountedPrice
        }
      })
    return discPrice
  }, [count, quantityDiscounts])
  return (
    <div className="price">
      {/* {currency?.symbol} */}
      {regularPrice && salePrice ? (
        <>
          <del>
            <span className="woocommerce-Price-amount amount">
              <bdi>
                {isRequiredCurrencySymball ? updatedCurrency?.symbol : ""}

                {/* {needToFixed ? (regularPrice ?? 0)?.toFixed(2) : regularPrice} */}
                {quantityDiscounts && quantityDiscounts.length > 0
                  ? roundedAmount(getDiscountedPrice)
                  : roundedAmount(regularPrice)}
              </bdi>
            </span>
          </del>
          <ins>
            <span className="woocommerce-Price-amount amount">
              <bdi>
                {isRequiredCurrencySymball ? updatedCurrency?.symbol : ""}

                {/* {needToFixed ? (salePrice ?? 0)?.toFixed(2) : salePrice} */}
                {quantityDiscounts && quantityDiscounts.length > 0
                  ? roundedAmount(getDiscountedPrice)
                  : roundedAmount(salePrice)}
              </bdi>
            </span>
          </ins>
        </>
      ) : regularPrice ? (
        <ins>
          <span className="woocommerce-Price-amount amount">
            <bdi>
              {isRequiredCurrencySymball ? updatedCurrency?.symbol : ""}

              {/* {needToFixed ? (regularPrice ?? 0)?.toFixed(2) : regularPrice} */}
              {quantityDiscounts && quantityDiscounts.length > 0
                ? roundedAmount(getDiscountedPrice)
                : roundedAmount(regularPrice)}
            </bdi>
          </span>
        </ins>
      ) : (
        <span
          style={{
            fontSize: "12px",
          }}
        >
          <bdi>Loading Pricing..</bdi>
        </span>
      )}
    </div>
  )
}

export default Price

// import React from "react"
// import { formatPrice, formatPriceForNonAuth } from "./utills"

// const sanitizeCurrency = (currencyString) => {
//   return currencyString.replace(/&nbsp;/g, ' ');
// };

// const Price = ({
//   regularPrice,
//   salePrice,
//   currency,
//   isRequiredCurrencySymball = false,
//   needToFixed = false,
// }) => {
//   const updatedCurrency = { ...currency }
//   updatedCurrency.rate = "1"

//   return (
//     <div className="price">
//       {/* {currency?.symbol} */}
//       {regularPrice && salePrice ? (
//         <>
//           <del>
//             <span className="woocommerce-Price-amount amount">
//               <bdi>
//                 {isRequiredCurrencySymball ? updatedCurrency?.symbol : ""}
//                 {needToFixed ? sanitizeCurrency((regularPrice ?? 0)?.toFixed(2)) : sanitizeCurrency(regularPrice)}
//               </bdi>
//             </span>
//           </del>
//           <ins>
//             <span className="woocommerce-Price-amount amount">
//               <bdi>
//                 {isRequiredCurrencySymball ? updatedCurrency?.symbol : ""}
//                 {needToFixed ? sanitizeCurrency((salePrice ?? 0)?.toFixed(2)) : sanitizeCurrency(salePrice)}
//               </bdi>
//             </span>
//           </ins>
//         </>
//       ) : regularPrice ? (
//         <ins>
//           <span className="woocommerce-Price-amount amount">
//             <bdi>
//               {isRequiredCurrencySymball ? updatedCurrency?.symbol : ""}
//               {needToFixed ? sanitizeCurrency((regularPrice ?? 0)?.toFixed(2)) : sanitizeCurrency(regularPrice)}
//             </bdi>
//           </span>
//         </ins>
//       ) : (
//         <span
//           style={{
//             fontSize: "12px",
//           }}
//         >
//           <bdi>Loading Pricing..</bdi>
//         </span>
//       )}
//     </div>
//   )
// }

// export default Price
