import React, { useContext } from "react"
import CartItemCard from "./CartItemCard"
import { CartContext } from "../store/cartContext"

export default function LeftSideCartSection() {
  const cartContext = useContext(CartContext)
  const { cartContextData: cartPageData } = cartContext

  return (
    <div className="col-lg-8 cart_sec_left">
      <table className="table" cellPadding={0} cellSpacing={0} border={0}>
        <thead>
          <tr>
            <th>
              <span>Product</span>
            </th>
            <th>{/* <span>Details</span> */}</th>
            <th>
              <span>Total</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {cartPageData?.contents?.nodes?.map(cartItem => (
            <CartItemCard
              key={cartItem.key}
              cartItem={cartItem}
              selectedCurrency={cartPageData?.currencySymbol}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}
